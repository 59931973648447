import React, { useEffect, Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { useLocation } from "@reach/router"

import {
  SectionContainer,
  SectionInnerWrapper,
  RowWrapper,
} from "../../components/Layout/styled"

import Footer from "../../components/Layout/Footer"
import SeoComp from "../../components/SeoComp"
import { isBrowser } from "../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

const KlarnaFaqPage = ({ intl, data }) => {
  const { pathname } = useLocation()
  const handleScrolling = data => {
    $("html, body").animate(
      {
        scrollTop: $(data.id).offset().top - 120,
      },
      1500
    )
  }

  const handleRedirects = () => {
    if (
      process.env.GATSBY_ACTIVE_ENV != "general" &&
      isBrowser &&
      process.env.GATSBY_ACTIVE_ENV != "gbp"
    ) {
      navigate("/")
    }
  }

  useEffect(() => {
    handleRedirects()
  }, [pathname])

  return (
    data &&
    data.allDirectusKlarnaFaqTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "faq",
            en: "faq",
            de: "faq",
            es: "faq",
          }}
        />
        <SectionContainer>
          <SectionInnerWrapper align="flex-start">
            {data.allDirectusKlarnaFaqTranslation.nodes[0]
              .introduction_section && (
              <IntroductionSection
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusKlarnaFaqTranslation.nodes[0]
                      .introduction_section,
                }}
              />
            )}
            <PageTitle>Q&A</PageTitle>
            <TopSection>
              {data.allDirectusKlarnaFaqTranslation.nodes[0].top_text.list.map(
                (item, index) => {
                  return (
                    <List key={index.toString()}>
                      {item.title && (
                        <ListText onClick={() => handleScrolling(item)}>
                          {item.title}
                        </ListText>
                      )}
                    </List>
                  )
                }
              )}
            </TopSection>
            <Title id="first">
              Klarna Pay later in 3 interest-free instalments
            </Title>
            {data.allDirectusKlarnaFaqTranslation.nodes[0].first_section && (
              <TextBox
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusKlarnaFaqTranslation.nodes[0].first_section,
                }}
              />
            )}
            <Title id="second">Pay later in 30 days</Title>
            {data.allDirectusKlarnaFaqTranslation.nodes[0].second_section && (
              <TextBox
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusKlarnaFaqTranslation.nodes[0]
                      .second_section,
                }}
              />
            )}
          </SectionInnerWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const PageTitle = styled.h1`
  color: #262626;
  font-size: 28px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin-bottom: 0.5em;
`

const Title = styled.h2`
  color: #262626;
  font-size: 20px;
  width: 100%;
  line-height: 1.4em;
  letter-spacing: -0.02em;
  font-family: "GothamLight";
  font-weight: 500;
  border-bottom: 2px solid #ececec;
`

const TopSection = styled.ul`
  color: #262626;
  margin: 0px 0px 10px 15px;
`

const List = styled.li`
  color: #262626;
`

const ListText = styled.a`
  color: #262626;
  margin-bottom: 30px;
  cursor: pointer;
  text-decoration: underline;
`

const IntroductionSection = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  letter-spacing: 0;

  h3 {
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    margin: 0px;
  }

  p {
    margin-bottom: 1.3em;
  }

  span {
    color: #ff6600;
  }

  a {
    text-decoration: underline;
    color: #038bff;
  }

  h2 {
    color: #262626;
    font-size: 20px;
    width: 100%;
    line-height: 1.4em;
    letter-spacing: -0.02em;
    font-family: "GothamLight";
    font-weight: 500;
    border-bottom: 2px solid #ececec;
  }

  .section1 {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .section1 div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section2 {
    display: flex;
    flex-direction: row;

    @media (max-width: 850px) {
      flex-direction: column;
    }
  }

  .section2 div {
    width: 100%;
  }

  .section2 div:nth-of-type(1) {
    margin-right: 10px;

    @media (max-width: 850px) {
      margin-right: 0px;
    }
  }

  .section2 div:nth-of-type(2) {
    margin-left: 10px;

    @media (max-width: 850px) {
      margin-left: 0px;
    }
  }

  ul {
    margin: 0px 0px 1.3em 30px;
  }

  ul li {
    margin: 0px 0px 10px;
  }
`

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  letter-spacing: 0;

  h3 {
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    margin: 0px;
  }

  p {
    margin-bottom: 1.3em;
  }

  span {
    color: #ff6600;
  }

  a {
    text-decoration: underline;
    color: #038bff;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusKlarnaFaqTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        introduction_section
        top_text {
          list {
            title
            id
          }
        }
        first_section
        second_section
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "faq" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(KlarnaFaqPage)
